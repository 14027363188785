import React from "react"
import { Link, graphql } from "gatsby"

// import Bio from "../components/bio"
import Layout from "../components/layout"
import Head from "../components/head"
import { rhythm } from "../utils/typography"
import '../styles/index.scss'




class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    
    const siteTitle = <h1>{data.site.siteMetadata.title}</h1>
    const posts = data.allContentfulPost.edges


    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Head />

        <a  style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `#C6281E`,
              fontSize:'1rem'
            }} href={"tel:+441442205808" } > <button type="button" style={{marginBottom:'20px'}} className="btn btn-success btn-cons">Call IT support</button>
          </a><br></br>
      {/*<Link to={'/search'} style={{ boxShadow: 'none' }} >
           <button type="button" style={{marginBottom:'20px'}} className="btn btn-primary btn-cons">Search for an article - requires <i className="fa fa-signal" aria-hidden="true"></i></button>
         </Link>*/}
        {/* <span className="toggletext">Toggle Search (requires internet) </span><label className="switch">
          <input type="checkbox"/>
          <span className="slider"></span>
        </label> */}


        {/* <Bio /> */}
        {posts.map(({ node }) => {

          const title = node.title || node.slug

          const brief=JSON.stringify(node.content.json.content[0].content[0].value)

          const truncate = (input) => input.length > 100 ? `${input.substring(0, 120)}...` : input;
         

          return (
            
            <Link to={node.slug}>
            <div key={node.slug} className="header">
              <div className="masthead">
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <span style={{ boxShadow: `none`, fontFamily: '"Verdana", "Geneva", sans-serif', color:"rgba(0,122,204,1)"}}>
                  {title}
                </span>
              </h3>
              <small>{node.createdAt}</small>
 
              <p
                dangerouslySetInnerHTML={{
                  __html: truncate(JSON.parse(brief)),
                }}
              />
            </div>
            </div>
            </Link>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
  allContentfulPost(sort: { fields: [priority, updatedAt], order: [ASC,DESC] }) {
    edges{
      node{
        title
        slug
        createdAt (formatString: "Do MMMM, YYYY")
        content{
          json
        }
      }
    }
  }
}
`
